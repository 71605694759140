import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService
  ) { }

  private loading: boolean = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getLoading(): boolean {
    return this.loading;
  }

  toastr(status: string, title: string, content: string) {
    this.toastrService.show(
      content,
      title,
      {
        status: status,
        destroyByClick: true,
        duration: 2000,
        hasIcon: false,
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        preventDuplicates: false,
      });
  }


  getImage(id: string): Observable<string> {
    return this.http.get<any>('http://localhost:8001/image/get');
  }

  buildRequestParams(params: any): any {
    var requestParams = {
      "limit": 20,
      "skip": 0,
      "sort": "id DESC",
      "fields": [
        "id",
        "email",
        "user_name"
      ],
      "conditions": [
        {
          "field": "email",
          "operator": "=",
          "value": "khanhvn991@gmail.com"
        },
        {
          "condition": "or",
          "field": "email",
          "operator": "=",
          "value": "khanhvn992@gmail.com"
        }
      ],
      "relations": [
        {
          "name": "CustomerAddresses",
          "fields": [
            "id",
            "customer_id",
            "address"
          ],
          "condition": [
            {
              "condition": "AND",
              "field": "email",
              "operator": "=",
              "value": "khanhvn991@gmail.com"
            }
          ]
        }
      ]
    };

    return requestParams;
  }
}
