import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ScrollbarHelper, DimensionsHelper, ColumnChangesService } from '@swimlane/ngx-datatable'

import {
  NbChatModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSidebarModule,
  NbSidebarService,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { ConfigService } from './services/config.service';
import { ApiInterceptorService } from './services/api-interceptor.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AdminComponent } from './admin.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonService } from './services/common.service';
import { InitService } from './services/init.service';

@NgModule({
  declarations: [AppComponent, AdminComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: (appConfigService: ConfigService) => () => appConfigService.loadAppConfig(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    // },
    Title,
    ScrollbarHelper, DimensionsHelper, ColumnChangesService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
