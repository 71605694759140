import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, map } from "rxjs/operators";
import { Router } from "@angular/router"
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token: string;
  public username: string;

  constructor(private http: HttpClient, private router: Router, private appConfig: ConfigService) {
    var user = localStorage.getItem('currentUser') || "";
    if (user) {
      var currentUser = JSON.parse(user);
    }
    this.token = currentUser && currentUser.token;
    this.username = currentUser && currentUser.usename;
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('currentUser');
    return authToken !== null ? true : false;
  }

  refreshToken(refreshToken: string): Observable<any> {
    // return of('foo').pipe(delay(1000));
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }

    const requestOptions = {
      headers: new Headers(headerDict),
    };
    return this.http.get(this.appConfig.apiBaseUrl + '/auth/refresh_token', {
      headers: new HttpHeaders({
        'Authorization': "Bearer " + refreshToken,
        'Content-Type': 'application/json'
      })
    })
    .pipe(map((response: any) => {
      let token = response.data.token;
      if (token) {
        this.token = token;
        // store username and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify({ username: this.username, token: this.token }));

        return this.token;
      } else {
        return this.token;
      }
    }));
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post(this.appConfig.apiBaseUrl + '/auth/login', JSON.stringify({ username: username, password: password }))
      .pipe(map((response: any) => {
        let token = response.data.token;
        if (token) {
          this.token = token;
          this.username = username;

          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token }));

          // return true to indicate successful login
          return true;
        } else {
          // return false to indicate failed login
          return false;
        }
      }));
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = "";
    localStorage.removeItem('currentUser');
    this.router.navigate(['auth/login'], { state: { redirect: this.router.url } })
  }
}
