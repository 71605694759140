import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { Ward } from '../../models/location/ward.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WardService {

  constructor(private http: HttpClient, private commonService: CommonService, private appConfig: ConfigService) { }

  getWards(params: any): Observable<any> {
    return this.http.post<Ward[]>(this.appConfig.apiBaseUrl + '/location/ward/list', JSON.stringify(params))
      .pipe(
        map((response: any) => response),
      )
  }

  getWard(id: string): Observable<Ward> {
    return this.http.get<Ward>(this.appConfig.apiBaseUrl + '/location/ward/get/' + id)
      .pipe(
        map((response: any) => response),
      )
  }
}
