import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { District } from '../../models/location/district.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  constructor(private http: HttpClient, private commonService: CommonService, private appConfig: ConfigService) { }

  getDistricts(params: any): Observable<any> {
    // params = this.commonService.buildRequestParams(params);
    return this.http.post<District[]>(this.appConfig.apiBaseUrl + '/location/district/list', JSON.stringify(params))
      .pipe(
        map((response: any) => response),
      )
  }

  getDistrict(id: string): Observable<District> {
    return this.http.get<District>(this.appConfig.apiBaseUrl + '/location/district/get/' + id)
      .pipe(
        map((response: any) => response),
      )
  }
}
