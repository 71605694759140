import { Injectable } from '@angular/core';
import { ProvinceService } from './location/province.service';
import { DistrictService } from './location/district.service';
import { WardService } from './location/ward.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private wardService: WardService) { }


  loadLocations() {
    this.syncProvince()
    this.syncDistrict()
    this.syncWard()
  }

  syncProvince() {
    var provinces = JSON.parse(localStorage.getItem('provinces'));
    if (!provinces || provinces?.lastUpdated < (new Date()).getTime() / 1000 - 30 * 24 * 60 * 60) {
      this.provinceService.getProvinces({
        limit: 100,
        fields: ["id", "name"],
        sort : "name"
      }).subscribe((res: any) => {
        var data = res.data
        provinces = [...data.data]
        localStorage.setItem("provinces", JSON.stringify({
          data: provinces,
          lastUpdated: (new Date()).getTime() / 1000
        }))
      });
    }
  }

  syncDistrict() {
    var districts = JSON.parse(localStorage.getItem('districts'));
    if (!districts || districts?.lastUpdated < (new Date()).getTime() / 1000 - 30 * 24 * 60 * 60) {
      this.districtService.getDistricts({
        limit: 20000,
        fields: ["id", "province_id", "name"],
        sort : "name"
      }).subscribe((res: any) => {
        var data = res.data
        districts = [...data.data]
        localStorage.setItem("districts", JSON.stringify({
          data: districts,
          lastUpdated: (new Date()).getTime() / 1000
        }))
      });
    }
  }

  syncWard() {
    var wards = JSON.parse(localStorage.getItem('wards'));
    if (!wards || wards?.lastUpdated < (new Date()).getTime() / 1000 - 30 * 24 * 60 * 60) {
      this.wardService.getWards({
        limit: 20000,
        fields: ["id", "district_id", "name"],
        sort : "name"
      }).subscribe((res: any) => {
        var data = res.data
        wards = [...data.data]
        localStorage.setItem("wards", JSON.stringify({
          data: wards,
          lastUpdated: (new Date()).getTime() / 1000
        }))
      });
    }
  }
}
