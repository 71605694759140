import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GroupService } from './authorization/group.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptorService implements HttpInterceptor {
  rs: any;
  constructor(
    public authService: AuthService, private appConfig: ConfigService, private injector: Injector,
    private groupService: GroupService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(request.url.indexOf('login') > -1) && !(request.url.indexOf('refresh_token') > -1)) {
      if (this.authService.token) {
        const helper = new JwtHelperService();
        const isExpired = helper.isTokenExpired(this.authService.token);
        if (isExpired) {
          // this.authService.logout();
          return from(this.authService.refreshToken(this.authService.token).pipe(switchMap((newToken: any) => {
            let token = newToken;
            return next.handle(this.addTokenHeader(request, token));
          })));
        }

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ` + this.authService.token,
            'Content-Type': `application/json;charset=utf-8`
          }
        });
      }

      return next.handle(request).pipe(catchError(err => {
        if ([401].includes(err.status)) {
          this.authService.logout();
        }
        if ([403].includes(err.status)) {
          // this.authService.logout();
        }

        // const error = err.error?.message || err.statusText;
        return throwError(err);
      }))
    }

    request = request.clone({
      setHeaders: {
        'Content-Type': `application/json;charset=utf-8`
      }
    });
    return next.handle(request);
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set("Authorization", 'Bearer ' + token) });
  }
}
