import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: 'CRM',
    group: true,
  },
  {
    title: 'Customer',
    icon: 'people-outline',
    children: [
      {
        title: 'Customer',
        link: '/customer/customer/list',
      },
    ],
  },
  {
    title: 'Authorization',
    icon: 'shield-outline',
    children: [
      {
        title: 'Application',
        link: '/authorization/application/list',
      },
      {
        title: 'Group',
        link: '/authorization/group/list',
      },
      {
        title: 'Rule',
        link: '/authorization/rule/list',
      },
    ],
  },
  {
    title: 'SES',
    group: true,
  },
  {
    title: 'Partner',
    icon: 'person-outline',
    children: [
      {
        title: 'Partner',
        link: '/partner/partner/list',
      },
      {
        title: 'Sender Email',
        link: '/partner/email/list',
      },
      {
        title: 'Template',
        link: '/partner/template/list',
      },
      {
        title: 'Schedule',
        link: '/partner/schedule/list',
      },
      {
        title: 'Funnel',
        link: '/partner/funnel/list',
      },
    ],
  },
  {
    title: 'SES',
    icon: 'email-outline',
    children: [
      {
        title: 'Credential',
        link: '/ses/credential/list',
      },
    ],
  },
  {
    title: 'Config',
    group: true,
  },
  {
    title: 'Config',
    icon: 'options-2-outline',
    children: [
      {
        title: 'Credential',
        link: '/ses/credential/list',
      },
    ],
  },
];
