import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {  Observable } from 'rxjs';
import { Group } from '../../models/group.model';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private http: HttpClient, private appConfig: ConfigService) { }

  createGroup(params: any): Observable<Group> {
    return this.http.post<Group[]>(this.appConfig.apiBaseUrl + '/group/create', JSON.stringify(params))
      .pipe(
        map((response: any) => response),
      );
  }

  getGroups(params: any): Observable<Group[]> {
    return this.http.post<Group[]>(this.appConfig.apiBaseUrl + '/group/list', JSON.stringify(params))
      .pipe(
        map((response: any) => response),
      );
  }

  getGroup(id: string): Observable<Group> {
    return this.http.get<Group>(this.appConfig.apiBaseUrl + '/group/get/' + id)
      .pipe(
        map((response: any) => response),
      );
  }

  getGroupPermission(id: string): Observable<Group> {
    return this.http.get<Group>(this.appConfig.apiBaseUrl + '/group/get-permission/' + id)
      .pipe(
        map((response: any) => response),
      );
  }

  savePermission(id: string, params: any): Observable<Group> {
    return this.http.post<Group>(this.appConfig.apiBaseUrl + '/group/save-permission/' + id, JSON.stringify(params))
      .pipe(
        map((response: any) => response),
      );
  }

  deleteGroup(id: number): Observable<Boolean> {
    return this.http.get<Group>(this.appConfig.apiBaseUrl + '/group/delete/' + id)
      .pipe(
        map((response: any) => response),
      );
  }
}
