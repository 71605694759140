import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private appConfig: any;
  private http: HttpClient;

  constructor(http: HttpClient, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  loadAppConfig() {
    return this.http.get('/assets/config/app-settings.json')
      .toPromise()
      .then(config => {
        this.appConfig = config;
      });
  }

  get apiBaseUrl(): string {
    return this.appConfig.apiBaseUrl;
  }
  get apiSesUrl(): string {
    return this.appConfig.apiSesUrl;
  }
  get apiProductUrl(): string {
    return this.appConfig.apiProductUrl;
  }
}
